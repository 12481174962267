@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'






























































































































.theme--light.v-data-table::v-deep
  .v-data-table__wrapper
    table
      tbody
        tr:hover
          cursor: pointer
